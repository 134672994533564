import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import { Accordion, AccordionItem } from '../../../../components/Accordion/Accordion';
import Link from '../../../../components/Link/Link';
import ExternalLink from '../../../../components/ExternalLink/ExternalLink';
import { getPathFromRouteKey } from '../../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../../config/RouteKeys';

import './style.scss';

type Props = PageProps & WithTranslation;

const BrokerPortalFAQ: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('faq.seo.title'),
    },
    page: {
      title: t('faq.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-image-4.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const brokerEmail = t(`${LocaleNameSpaceKeys.COMMON}:emails.brokerSupport`);
  const ariaEmailLabel = t(`${LocaleNameSpaceKeys.COMMON}:email`);

  const generateList = (
    list: {
      heading: string;
      questions: {
        key: string;
        question: string;
        answer: any;
        value?: string;
        link: {
          type: string;
          key: string;
        };
      }[];
    }[]
  ) => {
    return list.map((listItem) => (
      <div key={listItem.heading} className=".BrokerPortalFAQ__generic-gap">
        <h3>{listItem.heading}</h3>
        <Accordion allowMultipleExpanded allowZeroExpanded>
          {listItem.questions.map((q, index) => (
            <AccordionItem
              key={`${listItem.heading}-${index}`}
              buttonOptions={{ label: q.question }}
            >
              <Trans
                components={[
                  <Link
                    key={`${listItem.heading}-${index}-internal`}
                    to={
                      (q.link && q.link.key) ||
                      getPathFromRouteKey(RouteKeys.BROKER_CENTRE.VIDEO_LIBRARY)
                    }
                  />,
                  <ExternalLink
                    key={`${listItem.heading}-${index}-external`}
                    href={`mailto: ${brokerEmail}`}
                    aria-label={`${ariaEmailLabel} ${brokerEmail}`}
                  />,
                ]}
              >
                {q.answer}
              </Trans>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    ));
  };

  return (
    <Layout options={layoutOptions} className="BrokerPortalFAQ">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-80">
            {generateList(t('faq.sections', { returnObjects: true, brokerSupport: brokerEmail }))}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.BROKER_PORTAL)(BrokerPortalFAQ);
